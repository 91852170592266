import { Controller } from "@hotwired/stimulus"
import MiniSearch from "../minisearch.6.0.1.esm.js"

export default class extends Controller {
  static targets = ["input", "alert", "results", "template", "data"]

  connect() {
    this.reset()
    this.inputTarget.value = new URLSearchParams(document.location.search).get("q")
    const data = JSON.parse(this.dataTarget.innerHTML.trim())
    this.index = new MiniSearch({
      idField: "url", // Use URL as unique identifier
      fields: ["title", "tags", "content"], // Full-text search these fields
      storeFields: ["url", "title", "tags", "content", "date"], // Store the fields used for display
      searchOptions: {
        boost: { title: 5, tags: 5 }, // Boost name and tags for better sorting
        prefix: true, // Match word start, allowing 'psy' to match 'psychologue'
        fuzzy: 0.1 // Allow some fuzziness
      }
    })
    this.index.addAll(data)
    this.query()
  }

  search(e) {
    e.preventDefault()
    this.reset()
    this.query()
  }

  query() {
    const keywords = this.inputTarget.value.trim()
    if (keywords == "") {
      return
    }
    const results = this.index.search(keywords)
    if (results.length) {
      let matches = []
      results.forEach(result => matches.push(this.display(result)))
      this.resultsTarget.insertAdjacentHTML("beforeend", matches.join("\n"))
    } else {
      this.alertTarget.classList.remove("d-none")
      this.alertTarget.focus()
    }
  }

  reset() {
    this.resultsTarget.innerHTML = ""
    this.alertTarget.classList.add("d-none")
  }

  get template() {
    return this.templateTarget.innerHTML.trim()
  }

  display(result) {
    return this.template.replaceAll("__URL__", result["url"])
                        .replaceAll("__TITLE__", result["title"])
                        .replaceAll("__TAGS__", result["tags"].join(", ") || "aucun tags")
                        .replaceAll("__DATE__", result["date"])
                        .replaceAll("__CONTENT__", result["content"].slice(0, 256) + "…")
  }
}
