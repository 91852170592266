import { Controller } from "@hotwired/stimulus"

export default class extends Controller {
  static targets = ["yield", "ingredient", "buttons"]

  connect() {
    this.data.set("defaultOutput", this.yieldTarget.innerText)
  }

  increment() {
    this.output++
  }

  decrement() {
    this.output--
  }

  get output() {
    return parseInt(this.yieldTarget.innerText)
  }

  set output(value) {
    if (value == 0) {
      return
    }
    this.yieldTarget.innerHTML = value
    const defaultOutput = parseInt(this.data.get("defaultOutput"))
    this.ingredientTargets.forEach((el, i) => {
      this.setIngredientQuantity(el, defaultOutput, value)
    })
  }

  ingredientQuantity(el) {
    var value
    if (value = el.getAttribute("data-recipe-value")) {
      return parseFloat(value.replace(',', '.'))
    } else if (value = el.querySelector(".value")) {
      value = parseFloat(value.innerText.replace(',', '.')) || 0
      el.setAttribute("data-recipe-value", value)
      return value
    }
  }

  setIngredientQuantity(el, defaultOutput, output) {
    const quantity = this.ingredientQuantity(el)
    var value
    if (quantity != 0) {
      value = (quantity / defaultOutput) * output
      if (value < 20) {
        value = Math.round(value * 100) / 100
      } else {
        value = Math.round(value)
      }
      value = value + ''
      value = value.replace('.', ',')
      el.querySelector(".value").innerHTML = value
    }
  }
}
