/*
 * Color mode toggler adjusted from Bootstrap's docs (https://getbootstrap.com/)
 */

(() => {
  'use strict'

  const storedTheme = localStorage.getItem('theme')

  const darkMode = () => {
    return window.matchMedia('(prefers-color-scheme: dark)').matches
  }

  const getPreferredTheme = () => {
    if (storedTheme) {
      return storedTheme
    } if (darkMode()) {
      return 'dark'
    } else {
      return 'light'
    }
  }

  const setTheme = function (theme) {
    if (theme === 'auto' && darkMode()) {
      document.documentElement.setAttribute('data-bs-theme', 'dark')
    } else {
      document.documentElement.setAttribute('data-bs-theme', theme)
    }
  }

  setTheme(getPreferredTheme())

  const showActiveTheme = theme => {
    const activeThemeIcon = document.querySelector('#theme-icon-active')
    const btnToActivate = document.querySelector(`[data-bs-theme-value="${theme}"]`)
    const iconToActivate = document.querySelector(`#theme-icon-${theme}`)

    document.querySelectorAll('[data-bs-theme-value]').forEach(element => {
      element.classList.remove('active')
    })
    document.querySelectorAll('.theme-icon-current').forEach(element => {
      element.classList.add('d-none')
    })

    btnToActivate.classList.add('active')
    iconToActivate.classList.remove('d-none')
  }

  const handleThemeTogglers = () => {
    document.querySelectorAll('[data-bs-theme-value]')
    .forEach(toggle => {
      toggle.addEventListener('click', () => {
        const theme = toggle.getAttribute('data-bs-theme-value')
        localStorage.setItem('theme', theme)
        setTheme(theme)
        showActiveTheme(theme)
      })
    })
  }

  const handlePageLoad = () => {
    showActiveTheme(getPreferredTheme())
    handleThemeTogglers()
  }

  window.matchMedia('(prefers-color-scheme: dark)').addEventListener('change', () => {
    if (storedTheme !== 'light' || storedTheme !== 'dark') {
      setTheme(getPreferredTheme())
    }
  })

  window.addEventListener('DOMContentLoaded', () => {
    handlePageLoad()
  })
  window.addEventListener('turbo:load', () => {
    handlePageLoad()
  })
})()
